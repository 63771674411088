/* You can add global styles to this file, and also import other style files */
@keyframes enter-slide-down {
  from {
    top: 50%;
  }
  to {
    top: 150%;
  }
}
@keyframes enter-slide-up {
  from {
    top: 100%;
  }
  to {
    top: 50%;
  }
}
@keyframes enter-scaling {
  from {
    transform: scale(0.3) translate(-50%, -50%);
  }
  to {
    transform: scale(1) translate(-50%, -50%);
  }
}
@keyframes leave-scaling {
  from {
    transform: scale(1) translate(-50%, -50%);
  }
  to {
    transform: scale(0) translate(-50%, -50%);
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes overlay-leave {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    z-index: 0;
    position: static;
  }
}
@keyframes enter-slide-left {
  from {
    left: 150%;
  }
  to {
    left: 70%;
  }
}
@keyframes enter-slide-right {
  from {
    left: 70%;
  }
  to {
    left: 150%;
  }
}
:root {
  --custom-form-background: #8a6cc8;
  --custom-form-border: #7d5cc2;
  /** primary **/
  --color-primary-500: #8A6CC8;
  --color-primary-400: #A189D3;
  --color-primary-300: #B9A7DE;
  --color-primary-200: #D0C4E9;
  --color-primary-100: #E8E2F4;
  /** secondary **/
  --color-secondary-500: #212121;
  --color-secondary-400: #333333;
  --color-secondary-300: #666666;
  --color-secondary-200: #999999;
  --color-secondary-100: #CCCCCC;
  /** success **/
  --color-success-500: #4CAF50;
  --color-success-400: #70BF73;
  --color-success-300: #94CF96;
  --color-success-200: #B7DFB9;
  --color-success-100: #DBEFDC;
  /** warning **/
  --color-warning-500: #FF6700;
  --color-warning-400: #FF812B;
  --color-warning-300: #FFA76B;
  --color-warning-200: #FFC196;
  --color-warning-100: #FFF0E6;
  /** danger **/
  --color-danger-500: #FF3232;
  --color-danger-400: #FF5B5B;
  --color-danger-300: #FF8484;
  --color-danger-200: #FFADAD;
  --color-danger-100: #FFD6D6;
  /** natural **/
  --color-natural-500: #FFFFFF;
  --ff-primary: "DM Sans", sans-serif;
  --ff-body: var(--ff-primary);
  --ff-label: var(--ff-primary);
  --ff-heading: var(--ff-primary);
  --fw-bold: 700;
  --fw-semi-bold: 500;
  --fw-regular: 400;
  --fs-50: 0.512rem;
  --fs-100: 0.64rem;
  --fs-150: 0.8rem;
  --fs-200: 1rem;
  --fs-250: 1.25rem;
  --fs-300: 1.563rem;
  --fs-350: 1.953rem;
  --fs-400: 2.441rem;
  --fs-450: 3.052rem;
  --fs-500: 3.815rem;
  --fs-550: 4.768rem;
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
* {
  margin: 0;
  padding: 0;
  font-family: "DM Sans";
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role=list],
ol[role=list] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
  font-family: "DM Sans";
}

/* Set core body defaults */
body {
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: "DM Sans";
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
.pre-oauth-pages-container {
  display: grid;
  grid-template-columns: 0.5fr 0.5f;
  grid-template-areas: "container-left-side container-right-side";
  height: 100%;
}
.pre-oauth-pages-container .left-side {
  grid-area: container-left-side;
  background: var(--color-natural-500);
}
.pre-oauth-pages-container .right-side {
  grid-area: container-right-side;
  background: var(--color-natural-500);
}

.row-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.single-row-box {
  display: flex;
  width: 100%;
  padding: 12px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.Secondary-text-200 {
  color: var(--color-secondary-200);
  font-family: var(--ff-primary);
  font-size: var(--fs-150);
  font-style: normal;
  font-weight: var(--fw-semi-bold);
  line-height: normal;
}

.Small-secondary-text-200 {
  color: var(--color-secondary-200);
  font-family: var(--ff-primary);
  font-size: var(--fs-100);
  font-style: normal;
  font-weight: var(--fw-regular);
  line-height: normal;
}

.Bold-secondary-text-200 {
  color: var(--color-secondary-200);
  font-family: var(--ff-primary);
  font-size: var(--fs-250);
  font-style: normal;
  font-weight: var(--fw-bold);
  line-height: normal;
}

.Secondary-text-300 {
  color: var(--color-secondary-300);
  font-family: var(--ff-primary);
  font-size: var(--fs-150);
  font-style: normal;
  font-weight: var(--fw-semi-bold);
  line-height: normal;
}

.Small-secondary-text-300 {
  color: var(--color-secondary-300);
  font-family: var(--ff-primary);
  font-size: var(--fs-100);
  font-style: normal;
  font-weight: var(--fw-semi-bold);
  line-height: normal;
}

.Regular-secondary-text-300 {
  color: var(--color-secondary-300);
  font-family: var(--ff-primary);
  font-size: var(--fs-150);
  font-style: normal;
  font-weight: var(--fw-regular);
  line-height: normal;
}

.Big-secondary-text-300 {
  color: var(--color-secondary-300);
  font-family: var(--ff-primary);
  font-size: var(--fs-250);
  font-style: normal;
  font-weight: var(--fw-regular);
  line-height: normal;
}

.Bold-secondary-text-300 {
  color: var(--color-secondary-300);
  font-family: var(--ff-primary);
  font-size: var(--fs-150);
  font-style: normal;
  font-weight: var(--fw-bold);
  line-height: normal;
}

.Secondary-text-400 {
  color: var(--color-secondary-400);
  font-family: var(--ff-primary);
  font-size: var(--fs-150);
  font-style: normal;
  font-weight: var(--fw-semi-bold);
  line-height: normal;
}

.Bold-secondary-text-400 {
  color: var(--color-secondary-400);
  font-family: var(--ff-primary);
  font-size: var(--fs-250);
  font-style: normal;
  font-weight: var(--fw-bold);
  line-height: normal;
}

.Small-Secondary-header-500 {
  color: var(--color-secondary-500);
  font-family: var(--ff-primary);
  font-size: var(--fs-100);
  font-style: normal;
  font-weight: var(--fw-semi-bold);
  line-height: normal;
}

.Secondary-header-500 {
  color: var(--color-secondary-500);
  font-family: var(--ff-primary);
  font-size: var(--fs-150);
  font-style: normal;
  font-weight: var(--fw-semi-bold);
  line-height: normal;
}

.Medium-secondary-header-500 {
  color: var(--color-secondary-500);
  font-family: var(--ff-primary);
  font-size: var(--fs-200);
  font-style: normal;
  font-weight: var(--fw-semi-bold);
  line-height: normal;
}

.Bold-secondary-header-500 {
  color: var(--color-secondary-500);
  font-family: var(--ff-primary);
  font-size: var(--fs-300);
  font-style: normal;
  font-weight: var(--fw-bold);
  line-height: normal;
}

.Primary-text-200 {
  color: var(--color-primary-200);
  font-family: var(--ff-primary);
  font-size: var(--fs-150);
  font-style: normal;
  font-weight: var(--fw-semi-bold);
  line-height: normal;
}

.Primary-text-300 {
  color: var(--color-primary-300);
  font-family: var(--ff-primary);
  font-size: var(--fs-150);
  font-style: normal;
  font-weight: var(--fw-semi-bold);
  line-height: normal;
}

.Small-primary-text-200 {
  color: var(--color-primary-200);
  font-family: var(--ff-primary);
  font-size: var(--fs-100);
  font-style: normal;
  font-weight: var(--fw-semi-bold);
  line-height: normal;
}

.Primary-text-500 {
  color: var(--color-primary-500);
  font-family: var(--ff-primary);
  font-size: var(--fs-150);
  font-style: normal;
  font-weight: var(--fw-semi-bold);
  line-height: normal;
}

.Bold-primary-text-500 {
  color: var(--color-primary-500);
  font-family: var(--ff-primary);
  font-size: var(--fs-150);
  font-style: normal;
  font-weight: var(--fw-bold);
  line-height: normal;
}

.Small-primary-text-400 {
  color: var(--color-primary-500);
  font-family: var(--ff-primary);
  font-size: var(--fs-100);
  font-style: normal;
  font-weight: var(--fw-semi-bold);
  line-height: normal;
}

.Primary-text-400 {
  color: var(--color-primary-500);
  font-family: var(--ff-primary);
  font-size: var(--fs-150);
  font-style: normal;
  font-weight: var(--fw-semi-bold);
  line-height: normal;
}

.Neutral-text-500 {
  color: var(--color-natural-500);
  font-family: var(--ff-primary);
  font-size: var(--fs-150);
  font-style: normal;
  font-weight: var(--fw-semi-bold);
  line-height: normal;
}

.Bold-Neutral-text-500 {
  color: var(--color-natural-500);
  font-family: var(--ff-primary);
  font-size: var(--fs-250);
  font-style: normal;
  font-weight: var(--fw-semi-bold);
  line-height: normal;
}

.In-progress-text {
  color: #FBBC05;
  font-family: var(--ff-primary);
  font-size: var(--fs-100);
  font-style: normal;
  font-weight: var(--fw-semi-bold);
  line-height: normal;
}

.Resolved-text {
  color: #4CAF50;
  font-family: var(--ff-primary);
  font-size: var(--fs-100);
  font-style: normal;
  font-weight: var(--fw-semi-bold);
  line-height: normal;
}

.Detractor-text {
  color: #ff5b5b;
  font-family: var(--ff-primary);
  font-size: var(--fs-100);
  font-style: normal;
  font-weight: var(--fw-semi-bold);
  line-height: normal;
}

.Open-text {
  color: var(--color-primary-500);
  font-family: var(--ff-primary);
  font-size: var(--fs-100);
  font-style: normal;
  font-weight: var(--fw-semi-bold);
  line-height: normal;
}

.inactive-status-text {
  color: #333333;
}

.Assignee-name-text {
  color: var(--color-natural-500);
  font-family: var(--ff-primary);
  font-size: var(--fs-150);
  font-style: normal;
  font-weight: var(--fw-bold);
  line-height: normal;
}

.Small-Assignee-name-text {
  color: var(--color-natural-500);
  font-family: var(--ff-primary);
  font-size: var(--fs-100);
  font-style: normal;
  font-weight: var(--fw-bold);
  line-height: normal;
}

.Details-header {
  color: #000;
  font-family: var(--ff-primary);
  font-size: var(--fs-200);
  font-style: normal;
  font-weight: var(--fw-semi-bold);
  line-height: normal;
}

.Modal-header-text {
  color: var(--color-secondary-500);
  font-family: var(--ff-primary);
  font-size: var(--fs-250);
  font-style: normal;
  font-weight: var(--fw-bold);
  line-height: normal;
}

.Complaint-status-box {
  display: flex;
  width: 82.336px;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.space-between {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

a {
  justify-content: space-evenly;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.space-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.items-center {
  align-items: center;
}

.items-end {
  align-items: end;
}

.items-flex-end {
  align-items: flex-end;
}

.items-flex-start {
  align-items: flex-start;
}

.items-start {
  align-items: start;
}

.gap-0 {
  gap: 2px;
}

.gap-1 {
  gap: 4px;
}

.gap-2 {
  gap: 8px;
}

.gap-2-0 {
  gap: 10px;
}

.gap-3 {
  gap: 12px;
}

.gap-4 {
  gap: 16px;
}

.gap-5 {
  gap: 20px;
}

.gap-6 {
  gap: 24px;
}

.gap-7 {
  gap: 28px;
}

.gap-8 {
  gap: 32px;
}

.gap-9 {
  gap: 36px;
}

.gap-10 {
  gap: 40px;
}

.cursor-pointer {
  cursor: pointer;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.static {
  position: static;
}

.sticky {
  position: sticky;
}

.fixed {
  position: fixed;
}

.shadow-box {
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
}

.col {
  flex-direction: column;
}

.row {
  flex-direction: row;
}

.flex-wrap {
  flex-wrap: wrap;
}

.h-max {
  height: max-content;
}

.h-100 {
  height: 100%;
}

.h-75 {
  height: 75%;
}

.h-50 {
  height: 50%;
}

.h-25 {
  height: 25%;
}

.h-10 {
  height: 10%;
}

.w-100 {
  width: 100%;
}

.w-75 {
  width: 75%;
}

.w-50 {
  width: 50%;
}

.w-25 {
  width: 25%;
}

.w-10 {
  width: 10%;
}

.auto {
  margin: auto;
}

.mr-1 {
  margin-right: var(--mg-50);
}

.pointer {
  cursor: pointer;
}

.bg-primary-500 {
  background-color: var(--color-primary-500);
}

.bg-primary-400 {
  background-color: var(--color-primary-400);
}

.bg-primary-300 {
  background-color: var(--color-primary-300);
}

.bg-primary-200 {
  background-color: var(--color-primary-200);
}

.bg-primary-100 {
  background-color: var(--color-primary-100);
}

/** secondary **/
.bg-secondary-500 {
  background-color: var(--color-secondary-500);
}

.bg-secondary-400 {
  background-color: var(--color-secondary-400);
}

.bg-secondary-300 {
  background-color: var(--color-secondary-300);
}

.bg-secondary-200 {
  background-color: var(--color-secondary-200);
}

.bg-secondary-100 {
  background-color: var(--color-secondary-100);
}

/** success **/
.bg-success-500 {
  background-color: var(--color-success-500);
}

.bg-success-400 {
  background-color: var(--color-success-400);
}

.bg-success-300 {
  background-color: var(--color-success-300);
}

.bg-success-200 {
  background-color: var(--color-success-200);
}

.bg-success-100 {
  background-color: var(--color-success-100);
}

/** warning **/
.bg-warning-500 {
  background-color: var(--color-warning-500);
}

.bg-warning-400 {
  background-color: var(--color-warning-400);
}

.bg-warning-300 {
  background-color: var(--color-warning-300);
}

.bg-warning-200 {
  background-color: var(--color-warning-200);
}

.bg-warning-100 {
  background-color: var(--color-warning-100);
}

/** danger **/
.bg-danger-500 {
  background-color: var(--color-danger-500);
}

.bg-danger-400 {
  background-color: var(--color-danger-400);
}

.bg-danger-300 {
  background-color: var(--color-danger-300);
}

.bg-danger-200 {
  background-color: var(--color-danger-200);
}

.bg-danger-100 {
  background-color: var(--color-danger-100);
}

.bg--white {
  background: var(--color-natural-500);
}

.card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
}

.card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Text Justification Utilities */
.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.overflow-auto {
  overflow-y: auto;
}

/* Justify Content Utilities */
.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.space-center {
  justify-content: center;
}

.space-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

/* Align Items Utilities */
.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

/* Display Utilities */
.block {
  display: block;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.flex-coluum {
  flex-direction: column;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.inline-grid {
  display: inline-grid;
}

.table {
  display: table;
}

.table-cell {
  display: table-cell;
}

.hidden {
  display: none;
}

/* Font Weight Utilities */
.font-weight-100 {
  font-weight: 100;
}

.font-weight-200 {
  font-weight: 200;
}

.font-weight-300 {
  font-weight: 300;
}

.font-weight-400 {
  font-weight: normal;
  /* Equivalent to font-weight: 400; */
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: bold;
  /* Equivalent to font-weight: 700; */
}

.font-weight-800 {
  font-weight: 800;
}

.font-weight-900 {
  font-weight: 900;
}

/* Padding Utilities */
.w-vw {
  width: 100vw;
}

.w-max {
  width: max-content;
}

/* Height Utilities */
.h-5 {
  height: var(--h-5);
}

.h-10 {
  height: var(--h-10);
}

.h-15 {
  height: var(--h-15);
}

.h-20 {
  height: var(--h-20);
}

.h-25 {
  height: var(--h-25);
}

.h-30 {
  height: var(--h-30);
}

.h-35 {
  height: var(--h-35);
}

.h-40 {
  height: var(--h-40);
}

.h-45 {
  height: var(--h-45);
}

.h-50 {
  height: var(--h-50);
}

.h-55 {
  height: var(--h-55);
}

.h-60 {
  height: var(--h-60);
}

.h-65 {
  height: var(--h-65);
}

.h-70 {
  height: var(--h-70);
}

.h-75 {
  height: var(--h-75);
}

.h-80 {
  height: var(--h-80);
}

.h-85 {
  height: var(--h-85);
}

.h-90 {
  height: var(--h-90);
}

.h-95 {
  height: var(--h-95);
}

.h-vh {
  height: 100vh;
}

.text-white {
  color: #ffffff;
}

.text-black {
  color: #212121;
}

.text-black {
  color: #852020;
}

/* Position Utilities */
/* Static Position */
.position-static {
  position: static;
}

/* Relative Position */
.position-relative {
  position: relative;
}

/* Absolute Position */
.position-absolute {
  position: absolute;
}

/* Fixed Position */
.position-fixed {
  position: fixed;
}

/* Sticky Position */
.position-sticky {
  position: sticky;
  top: 0;
}

.padding-1 {
  padding: 10px 24px 10px 20px;
}

/* Box Shadow Utilities */
/* Small shadow */
.shadow-sm {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

/* Regular shadow */
.shadow {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Large shadow */
.shadow-lg {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

/* Extra large shadow */
.shadow-xl {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Inner shadow */
.shadow-inner {
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

*::-webkit-scrollbar,
body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  /* width of the entire scrollbar */
}

*::-webkit-scrollbar-track,
body::-webkit-scrollbar-track {
  background: #fff;
  /* color of the tracking area */
}

*::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
  background-color: #e5e5e5;
  /* color of the scroll thumb */
  border-radius: 6px;
  /* roundness of the scroll thumb */
  border: 2px solid #fff;
  /* creates padding around scroll thumb */
}

@media screen and (max-width: 768px) {
  .pre-oauth-pages-container {
    display: grid;
    grid-template-columns: 1fr 0fr;
    grid-template-areas: "container-left-side container-right-side";
    height: 100%;
  }
  .pre-oauth-pages-container .left-side {
    grid-area: container-left-side;
    background: var(--color-primary-500);
  }
  .pre-oauth-pages-container .right-side {
    grid-area: container-right-side;
    background: var(--color-secondary-400);
    display: none;
  }
  .b-md-start {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }
  .b-md {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }
}