@keyframes enter-slide-down {
    from {
      top: 50%;
    }
    to {
      top: 150%;
    }
  }
  @keyframes enter-slide-up {
    from {
      top: 100%;
    }
    to {
      top: 50%;
    }
  }
  
  @keyframes enter-scaling {
    from {
      transform: scale(0.3) translate(-50%, -50%);
    }
    to {
      transform: scale(1) translate(-50%, -50%);
    }
  }
  
  @keyframes leave-scaling {
    from {
      transform: scale(1) translate(-50%, -50%);
    }
    to {
      transform: scale(0) translate(-50%, -50%);
    }
  }
  
  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fade-out {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  
  @keyframes overlay-leave {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
      z-index: 0;
      position: static;
    }
  }


  // side modal


  @keyframes enter-slide-left {
    from {
      left: 150%;
    }
    to {
      left: 70%;
    }
  }
  @keyframes enter-slide-right {
    from {
      left: 70%;
    }
    to {
      left: 150%;
    }
  }